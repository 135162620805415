import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import * as activeChargingAnimationDetail from "./json/active_charging_detail.json";
import * as pendingChargingAnimationDetail from "./json/pending_charging_detail.json";
import bgStoppedCharging from "../../../assets/images/transaction/bg_stopped_charging.jpg";
import {
  Card,
  Typography,
  Row,
  Col,
  Divider,
  Button,
  message,
  Modal,
} from "antd";
import { Svgs } from "../../../components/Svgs";
import { useTranslation } from "react-i18next";
import Chart from "../../../components/charts/Chart";
import { PoweroffOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { formatShortDateToString, formatShortTime } from "../../../utils";
import {
  apiGetTransactionActiveDetail,
  apiStopCharging,
} from "../../../services/CommonServices";

const { Text } = Typography;

export const Detail = ({ transactionId }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [transaction, setTransaction] = useState(null);
  const [backgroundCard, setBackGroundCard] = useState(null);
  const fetchData = async () => {
    if (id || transactionId) {
      try {
        const res = await apiGetTransactionActiveDetail({
          transaction_id: id ?? transactionId,
        });
        setTransaction(res?.data);
      } catch (e) {
        console.log(e);
      }
    } else {
      message.error(t("something_went_wrong"));
    }
  };

  useEffect(() => {
    if (!["stopped", "cancelled"].includes(transaction?.status.toLowerCase())) {
      fetchData();
      const intervalId = setInterval(() => {
        fetchData();
      }, 10000);
      return () => clearInterval(intervalId);
    }
  }, [transactionId, id, transaction?.status]);

  const [loadingStop, setLoadingStop] = useState(false);
  const handleStopCharging = async () => {
    try {
      setLoadingStop(true);
      const res = await apiStopCharging({
        transaction_id: id ?? transactionId,
      });
      setBackGroundCard(null);
    } catch {
      message.error(t("something_went_wrong"));
    } finally {
      setLoadingStop(false);
    }
  };
  const showConfirm = () => {
    Modal.confirm({
      title: t("confirm_stop_title"),
      okText: t("yes"),
      cancelText: t("cancel"),
      okButtonProps: {
        style: {
          backgroundColor: "#B20070",
          borderColor: "#B20070",
          color: "#fff",
        },
      },
      onOk() {
        handleStopCharging();
      },
    });
  };

  useEffect(() => {
    if (
      ["pendingstart", "pendingstop"].includes(
        transaction?.status.toLowerCase()
      )
    ) {
      setBackGroundCard(pendingChargingAnimationDetail);
    } else if (["ongoing"].includes(transaction?.status.toLowerCase())) {
      setBackGroundCard(activeChargingAnimationDetail);
    } else if (
      ["stopped", "cancelled"].includes(transaction?.status.toLowerCase())
    ) {
      setBackGroundCard(null);
    }
  }, [transaction?.status]);

  const dataPowerCharts = [
    {
      name: "Công suất",
      data: transaction?.capacity?.length
        ? transaction?.capacity?.map((item) => item?.value)
        : [0, 0, 0, 0, 0, 0, 0],
      type: "area",
      color: "#B20070",
    },
  ];
  const dataAmpeCharts = [
    {
      name: "Dòng điện",
      data: transaction?.ampe?.length
        ? transaction?.ampe?.map((item) => item?.value)
        : [0, 0, 0, 0, 0, 0, 0],
      type: "area",
      color: "#B20070",
    },
  ];

  const dataVolCharts = [
    {
      name: "Điện áp",
      data: transaction?.vol?.length
        ? transaction?.vol?.map((item) => item?.value)
        : [0, 0, 0, 0, 0, 0, 0],
      type: "area",
      color: "#F29913",
    },
  ];
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          display: "inline-block",
          width: "100%",
        }}
      >
        {backgroundCard ? (
          <Lottie
            animationData={backgroundCard}
            loop={true}
            style={{
              width: "100%",
              height: "352px",
            }}
          />
        ) : (
          <>
            <img src={bgStoppedCharging} height={352} width={"100%"}></img>
          </>
        )}
        <div
          style={{
            position: "absolute",
            top: backgroundCard ? "48%" : "55%",
            left: backgroundCard ? "26%" : "24%",
            transform: "translateY(-80%)",
            zIndex: 2,
          }}
        >
          <Typography.Text
            style={{ fontSize: "20px", fontWeight: "600", color: "#FFFFFF" }}
          >
            {transaction?.soc ?? 0}{" "}
            <span
              style={{ fontSize: "12px", fontWeight: "400", color: "#E4E4E7" }}
            >
              %
            </span>
          </Typography.Text>
        </div>
      </div>
      <div style={{ padding: "16px", width: "100%" }}>
        <Card
          bordered
          style={{
            width: "100%",
            padding: 16,
            backgroundColor: "#f5f5f5",
            borderRadius: 8,
            borderColor: "#e0e0e0",
          }}
          bodyStyle={{ padding: "0px" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <Svgs.IconLocationSvg style={{ marginRight: "8px" }} />
            <Typography.Text style={{ fontSize: "14px", fontWeight: "500" }}>
              {transaction?.station_address || t("none")}
            </Typography.Text>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "8px",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "50%" }}
            >
              <Svgs.IconCharger1Svg style={{ marginRight: "8px" }} />
              <Typography.Text style={{ fontSize: "14px", fontWeight: "500" }}>
                {transaction?.connector_id || t("none")}
              </Typography.Text>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", width: "50%" }}
            >
              <Svgs.IconTagSvg style={{ marginRight: "8px" }} />
              <Typography.Text style={{ fontSize: "14px", fontWeight: "500" }}>
                {transaction?.id_tag || t("none")}
              </Typography.Text>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "8px",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "50%" }}
            >
              <Svgs.IconTimerSvg style={{ marginRight: "8px" }} />
              <Typography.Text style={{ fontSize: "14px", fontWeight: "500" }}>
                {transaction?.start_time
                  ? formatShortDateToString(transaction?.start_time)
                  : "00:00"}
              </Typography.Text>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", width: "50%" }}
            >
              <Svgs.IconTimeChargingSvg style={{ marginRight: "8px" }} />
              <Typography.Text style={{ fontSize: "14px", fontWeight: "500" }}>
                {transaction?.time_charging
                  ? formatShortTime(transaction?.time_charging)
                  : "00:00"}
              </Typography.Text>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ display: "flex", alignItems: "center", width: "50%" }}
            >
              <Svgs.IconPlugSvg style={{ marginRight: "8px" }} />
              <Typography.Text style={{ fontSize: "14px", fontWeight: "500" }}>
                {transaction?.power
                  ? parseInt(transaction?.power, 10) / 1000
                  : 0}{" "}
                Kwh
              </Typography.Text>
            </div>
            {/* <div
              style={{ display: "flex", alignItems: "center", width: "50%" }}
            >
              <Svgs.IconEVCSvg style={{ marginRight: "8px" }} />
              <Typography.Text style={{ fontSize: "14px", fontWeight: "500" }}>
                {transaction?.charging_cost || 0} EVC
              </Typography.Text>
            </div> */}
          </div>
        </Card>
        <div style={{ marginTop: "24px", width: "100%" }}>
          <Typography.Title
            style={{
              fontSize: "16px",
              fontWeight: "500",
              marginTop: "0px",
            }}
          >
            {t("power")}
          </Typography.Title>
          <Typography.Title
            style={{
              fontSize: "12px",
              fontWeight: "400",
              marginTop: "0px",
              color: "#52525B",
              marginBottom: "0px",
            }}
          >
            Power (kW)
          </Typography.Title>
          <Chart
            options={{
              chart: {
                zoom: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
              },
              fill: {
                type: "gradient",
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.6,
                  opacityTo: 0.9,
                  stops: [0, 80, 100],
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
                width: 3,
              },
              labels: transaction?.capacity?.length
                ? transaction?.capacity?.map((item) => item?.label)
                : ["01", "02", "03", "04", "05", "06", "07"],
              yaxis: {
                show: true,
                max: 200,
              },
              grid: {
                show: true,
              },
              tooltip: {
                y: {
                  formatter: (val) => `${val} kW`,
                },
              },
              legend: {
                horizontalAlign: "left",
              },
            }}
            type="area"
            series={dataPowerCharts}
            height={200}
            width={"100%"}
          />
        </div>
        <div style={{ marginTop: "24px", width: "100%" }}>
          <Typography.Title
            style={{
              fontSize: "16px",
              fontWeight: "500",
              marginTop: "0px",
            }}
          >
            {t("ample")}
          </Typography.Title>
          <Typography.Title
            style={{
              fontSize: "12px",
              fontWeight: "400",
              marginTop: "0px",
              color: "#52525B",
              marginBottom: "0px",
            }}
          >
            Ampe (A)
          </Typography.Title>
          <Chart
            options={{
              chart: {
                zoom: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
              },
              fill: {
                type: "gradient",
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.6,
                  opacityTo: 0.9,
                  stops: [0, 80, 100],
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
                width: 3,
              },
              labels: transaction?.ampe?.length
                ? transaction?.ampe?.map((item) => item?.label)
                : ["01", "02", "03", "04", "05", "06", "07"],
              yaxis: {
                show: true, // Hide Y-axis labels,
                max: 200,
              },
              grid: {
                show: true, // Hide grid lines
              },
              tooltip: {
                y: {
                  formatter: (val) => `${val} A`,
                },
              },
              legend: {
                horizontalAlign: "left",
              },
            }}
            type="area"
            series={dataAmpeCharts}
            height={200}
            width={"100%"}
          />
        </div>
        <div style={{ marginTop: "24px", width: "100%" }}>
          <Typography.Title
            style={{
              fontSize: "16px",
              fontWeight: "500",
              marginTop: "0px",
            }}
          >
            {t("volt")}
          </Typography.Title>
          <Typography.Title
            style={{
              fontSize: "12px",
              fontWeight: "400",
              marginTop: "0px",
              color: "#52525B",
              marginBottom: "0px",
            }}
          >
            Volt (V)
          </Typography.Title>
          <Chart
            options={{
              chart: {
                zoom: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
              },
              fill: {
                type: "gradient",
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.6,
                  opacityTo: 0.9,
                  stops: [0, 80, 100],
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
                width: 3,
              },
              labels: transaction?.vol?.length
                ? transaction?.vol?.map((item) => item?.label)
                : ["01", "02", "03", "04", "05", "06", "07"],
              yaxis: {
                show: true, // Hide Y-axis labels,
                max: 500,
              },
              grid: {
                show: true, // Hide grid lines
              },
              tooltip: {
                y: {
                  formatter: (val) => `${val} V`,
                },
              },
              legend: {
                horizontalAlign: "left",
              },
            }}
            type="area"
            series={dataVolCharts}
            height={200}
            width={"100%"}
          />
        </div>
        <Divider style={{ marginTop: "16px", marginBottom: "12px" }} />
        {["ongoing", "pendingstop"].includes(
          transaction?.status.toLowerCase()
        ) && (
          <Button
            loading={
              loadingStop ||
              ["pendingstop"].includes(transaction?.status.toLowerCase())
            }
            style={{
              width: "100%",
              backgroundColor: "#FA5050",
              color: "#fff",
              height: "44px",
              fontWeight: "600",
            }}
            onClick={showConfirm}
          >
            <PoweroffOutlined style={{ fontWeight: "600" }} />
            Dừng phiên sạc
          </Button>
        )}
      </div>
    </div>
  );
};
