import HttpService from "./HttpService";

export const apiGetToken = (data) => {
  return HttpService.get("users/auth/amlb/token", { params: data });
};

export const apiStartCharging = (data) => {
  return HttpService.post("/transactions/start", data);
};

export const apiStopCharging = (data) => {
  return HttpService.post("/transactions/stop", data);
};

export const apiGetTransactionsHistory = (data) => {
  return HttpService.get("/transactions/history", { params: data });
};

export const apiGetTransactionsActive = (data) => {
  return HttpService.get("/transactions/active", { params: data });
};

export const apiGetDataChartTransactionHistory = (data) => {
  return HttpService.get("/home/transactions/history", { params: data });
};

export const apiGetTransactionActiveDetail = (data) => {
  return HttpService.get("/transactions/detail", { params: data });
};