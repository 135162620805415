import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Button, Dropdown, Menu, Divider } from "antd";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaCaretDown, FaTimes } from "react-icons/fa";
import { Svgs } from "./components/Svgs";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";

const { Header } = Layout;

const AppHeader = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return t("greeting");
    if (hour < 18) return t("greeting_afternoon");
    return t("greeting_evening");
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const languageMenu = (
    <Menu>
      <Menu.Item key="en" onClick={() => changeLanguage("vi")}>
        <span style={{ display: "inline-flex", alignItems: "center" }}>
          <Svgs.FlagViSvg
            style={{ marginRight: 8, height: "16px", width: "16px" }}
          />
          Tiếng Việt
        </span>
      </Menu.Item>
      <Menu.Item key="vi" onClick={() => changeLanguage("en")}>
        <span style={{ display: "inline-flex", alignItems: "center" }}>
          <Svgs.FlagUsaSvg
            style={{
              marginRight: 8,
              height: "16px",
              width: "16px",
              borderRadius: "50%",
            }}
          />
          English
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header
      className="header-bg"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "4px 16px 0px 16px",
        flexDirection: "column",
        height: "116px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          {/* First Logo Above Greeting */}
          <Svgs.LogoAeonSvg style={{ height: "28px" }} />
          {location.pathname === "/home" || location.pathname === "/" ? (
            <div className="greeting">{getGreeting()}</div>
          ) : (
            <>
              <div className="greeting">
                <Button
                  type="link"
                  style={{ color: "#FFF" }}
                  onClick={() => navigate("/")}
                  icon={<FaArrowLeft />}
                />
              </div>
            </>
          )}
        </div>

        <div>
          <Svgs.LogoEVCSvg
            style={{ height: "28px", cursor: "pointer" }}
            onClick={() => {
              window.open("https://evercharge.vn/");
            }}
          ></Svgs.LogoEVCSvg>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                height: "32px",
                width: "52px",
                padding: "4px 8px 4px 4px",
                backgroundColor: "#18181B33",
                borderRadius: "32px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Dropdown overlay={languageMenu} placement="bottomRight" arrow>
                <Button
                  type="link"
                  className="language-button"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    padding: "0px",
                    borderRadius: "50%",
                  }}
                >
                  {i18n.language === "en" ? (
                    <Svgs.FlagUsaSvg
                      style={{
                        height: "24px",
                        width: "24px",
                        borderRadius: "100%",
                      }}
                    />
                  ) : (
                    <Svgs.FlagViSvg
                      style={{
                        height: "24px",
                        width: "24px",
                        borderRadius: "100%",
                      }}
                    />
                  )}
                  <DownOutlined style={{ fontSize: "8px" }} />
                </Button>
              </Dropdown>
              {/* <Divider
                type="vertical"
                style={{
                  color: "#fff",
                  backgroundColor: "#E4E4E7",
                  height: "16px",
                }}
              />
              <Button
                type="link"
                icon={<CloseOutlined size={11} />}
                style={{ color: "#fff", fontWeight: "400" }}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default AppHeader;
