import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Layout, Typography } from "antd";
import "./App.css"; // Import the CSS file
import { Home } from "./views/Home";
import { Transactions } from "./views/Transactions";
import AppHeader from "./AppHeader";
import { AuthGuard } from "./components/AuthGuard";
import { TransactionDetail } from "./views/TransactionDetail";

const { Content, Footer } = Layout;

function App() {
  return (
    <Router>
      <div className="container">
        <Layout>
          <AppHeader />
          <Content className="site-layout-content">
            <AuthGuard>
              <Routes>
                {/* Protect routes with AuthGuard */}
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/transactions" element={<Transactions />} />
                <Route path="/transaction/detail/:id" element={<TransactionDetail />} />
              </Routes>
            </AuthGuard>
          </Content>
          <Footer className="footer">
            EverCharge. All rights reserved. © Copyright{" "}
            {`${new Date().getFullYear()}`}{" "}
          </Footer>
        </Layout>
      </div>
    </Router>
  );
}

export default App;
