import { Card, Typography, Select, message, Spin } from "antd";
import Chart from "../../../components/charts/Chart";
import { COLOR_1 } from "../../../components/charts/chart.constant";
import { useTranslation } from "react-i18next";
import { apiGetDataChartTransactionHistory } from "../../../services/CommonServices";
import { useEffect, useState } from "react";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

export const StatisticsChart = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dataCharts, setDataCharts] = useState([]);
  const [labels, setLabels] = useState([]);
  const [type, setType] = useState("7");
  const [params, setParams] = useState({
    start_date: moment().subtract(6, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    type: "RANGE_DAY",
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await apiGetDataChartTransactionHistory(params);
      if (res?.data) {
        const convertData = [
          {
            name: "Công suất sạc",
            data: Object.values(res?.data).map((item) => item.power),
            type: "area",
            color: "#B20070",
          },
        ];
        setDataCharts(convertData);
        setLabels(
          Object.keys(res?.data).map((date) => moment(date).format("DD-MM"))
        );
      }
    } catch (e) {
      message.error(t("something_went_wrong"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [params]);

  const handleChangeType = (option) => {
    let startDate, endDate;
    setType(option);
    if (option === "7") {
      startDate = moment().subtract(6, "days").format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    } else if (option === "14") {
      startDate = moment().subtract(13, "days").format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    } else if (option === "30") {
      startDate = moment().subtract(29, "days").format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    }

    setParams({
      ...params,
      start_date: startDate,
      end_date: endDate,
      type: "RANGE_DAY",
    });
  };

  const totalPower =
    dataCharts[0]?.data.reduce((acc, val) => acc + val, 0) || 0;

  return (
    <>
      <Typography.Title
        style={{ fontSize: "16px", fontWeight: "500", marginTop: "0px" }}
      >
        {t("power_statistics")}
      </Typography.Title>
      <Card style={{ borderRadius: "12px" }} bodyStyle={{ padding: "0px" }}>
        {loading ? (
          <div style={{ textAlign: "center", padding: 100 }}>
            <Spin indicator={<LoadingOutlined spin />} size="small" />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
              }}
            >
              <div style={{ textAlign: "left" }}>
                <Typography.Text
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#B20070",
                  }}
                >
                  {totalPower}{" "}
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    kWh
                  </span>
                </Typography.Text>
                <Typography.Text
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    display: "block",
                    color: "#52525B",
                  }}
                >
                  {t("total_power")}
                </Typography.Text>
              </div>
              <Select
                defaultValue="7"
                value={type}
                style={{ width: 100, color: "#71717A", fontSize: "14px" }}
                onChange={handleChangeType}
              >
                <Option value="7">{t("7_days")}</Option>
                <Option value="14">{t("14_days")}</Option>
                {/* <Option value="30">{t("30_days")}</Option> */}
              </Select>
            </div>
            <Chart
              options={{
                chart: {
                  zoom: {
                    enabled: false,
                  },
                  toolbar: {
                    show: false,
                  },
                },
                fill: {
                  type: "gradient",
                  gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.6,
                    opacityTo: 0.9,
                    stops: [0, 80, 100],
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                  width: 3,
                },
                labels: labels,
                yaxis: {
                  show: false,
                },
                grid: {
                  show: false,
                },
                tooltip: {
                  y: {
                    formatter: (val) => `${val} kWh`,
                  },
                },
                legend: {
                  horizontalAlign: "left",
                },
              }}
              type="area"
              series={dataCharts}
              height={200}
            />
          </>
        )}
      </Card>
    </>
  );
};
