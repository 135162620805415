import React, { useEffect, useState } from "react";
import { Typography, Button, Modal, message } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import { Svgs } from "../../../components/Svgs";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import * as activeChargingAnimation from "./json/active_charging.json";
import * as pendingChargingAnimation from "./json/pending.json";
import * as connectingChargingAnimation from "./json/connecting.json";
import {
  apiGetTransactionActiveDetail,
  apiStopCharging,
} from "../../../services/CommonServices";
import { formatShortTime } from "../../../utils";

export const ActiveCharging = ({ setIsActive, transactionId }) => {
  const { t } = useTranslation();
  const [transaction, setTransaction] = useState(null);
  const [backgroundCard, setBackGroundCard] = useState(
    pendingChargingAnimation
  );
  const fetchData = async () => {
    try {
      const res = await apiGetTransactionActiveDetail({
        transaction_id: transactionId,
      });
      setTransaction(res?.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [transactionId]);

  const [loadingStop, setLoadingStop] = useState(false);
  const handleStopCharging = async () => {
    try {
      setLoadingStop(true);
      const res = await apiStopCharging({
        transaction_id: transactionId,
      });
      setIsActive(false);
    } catch {
      message.error(t("something_went_wrong"));
    } finally {
      setLoadingStop(false);
    }
  };
  const showConfirm = () => {
    Modal.confirm({
      title: t("confirm_stop_title"),
      okText: t("yes"),
      cancelText: t("cancel"),
      okButtonProps: {
        style: {
          backgroundColor: "#B20070",
          borderColor: "#B20070",
          color: "#fff",
        },
      },
      onOk() {
        handleStopCharging();
      },
    });
  };
  useEffect(() => {
    if (
      ["pendingstart", "pendingstop"].includes(
        transaction?.status.toLowerCase()
      )
    ) {
      setBackGroundCard(pendingChargingAnimation);
    } else if (["ongoing"].includes(transaction?.status.toLowerCase())) {
      setBackGroundCard(activeChargingAnimation);
    } else if (
      ["stopped", "cancelled"].includes(transaction?.status.toLowerCase())
    ) {
      setIsActive(false);
      message.success("Phiên sạc đã kết thúc!");
    }
  }, [transaction?.status]);
  return (
    <div
      style={{
        position: "relative",
        borderRadius: "8px",
        overflow: "hidden",
        display: "inline-block",
        width: "100%",
      }}
    >
      <Lottie
        animationData={backgroundCard}
        loop={true}
        style={{
          width: "100%",
          height: "auto",
        }}
      />

      {/* Top overlay for stop button */}
      <div
        style={{
          position: "absolute",
          top: "16px",
          right: "16px",
          zIndex: 2,
        }}
      >
        <Button
          loading={loadingStop}
          type="primary"
          icon={<PoweroffOutlined />}
          onClick={showConfirm}
          style={{
            backgroundColor: "#FF4D4F",
            borderColor: "#FF4D4F",
            fontWeight: "bold",
          }}
        ></Button>
      </div>

      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "11.5%",
          transform: "translateY(-80%)",
          zIndex: 2,
        }}
      >
        <Typography.Text
          style={{ fontSize: "14px", fontWeight: "600", color: "#FFFFFF" }}
        >
          {transaction?.soc ?? 0}{" "}
          <span
            style={{ fontSize: "10px", fontWeight: "400", color: "#E4E4E7" }}
          >
            %
          </span>
        </Typography.Text>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          padding: "16px",
          display: "flex",
          justifyContent: "space-around",
          zIndex: 2,
          borderRadius: "0 0 8px 8px",
        }}
      >
        <div style={{ textAlign: "center", color: "white" }}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px",
              borderRadius: "4px",
              background: "rgba(255, 255, 255, 0.1)",
              minWidth: "151px",
              height: "32px",
              marginRight: "8px",
            }}
          >
            <Svgs.TimeChargingIconSvg
              style={{ fontSize: "20px", marginRight: "4px" }}
            />
            <Typography.Text
              style={{
                color: "rgba(255, 255, 255, 0.8)",
                fontWeight: "500",
                fontSize: "12px",
              }}
            >
              {transaction?.time_charging
                ? formatShortTime(transaction?.time_charging)
                : "00:00"}
            </Typography.Text>
          </div>
        </div>

        <div style={{ textAlign: "center", color: "white" }}>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px",
              borderRadius: "4px",
              background: "rgba(255, 255, 255, 0.1)",
              minWidth: "151px",
              height: "32px",
              marginRight: "8px",
            }}
          >
            <Svgs.PlugIconSvg
              style={{ fontSize: "20px", marginRight: "4px" }}
            />
            <Typography.Text
              style={{
                color: "rgba(255, 255, 255, 0.8)",
                fontWeight: "500",
                fontSize: "12px",
              }}
            >
              {transaction?.power ? parseInt(transaction?.power, 10) / 1000 : 0}{" "}
              Kwh
            </Typography.Text>
          </div>
        </div>

      </div>
    </div>
  );
};
