import React, { useEffect, useState } from "react";
import { Button, Card, message, Typography } from "antd";
import "./index.css";
import RecentCharges from "./components/RecentCharges";
import { ScanQR } from "./components/ScanQR";
import { ActiveCharging } from "./components/ActiveCharging";
import { StatisticsChart } from "./components/StatisticsChart";
import {
  apiGetTransactionsActive,
  apiStartCharging,
} from "../../services/CommonServices";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export const Home = () => {
  const { Title } = Typography;
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const session_id = searchParams.get("session_id");
  const navigate = useNavigate();
  const [transactionId, setTransactionId] = useState(null);
  const [data, setData] = useState([]);

  const fetchTransactionActive = async () => {
    try {
      setLoading(true);
      const resActive = await apiGetTransactionsActive({ page: 0, size: 5 });
      setData(resActive?.data);
      if (resActive?.data?.length) {
        setIsActive(true);
        setTransactionId(resActive?.data?.[0]?.transaction_id);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchTransactionActive();
  }, []);
  const onChangeQR = async (valueQR) => {
    try {
      setLoading(true);
      const res = await apiStartCharging({
        qr_code: valueQR?.text,
      });
      setIsActive(true);
      fetchTransactionActive();
    } catch (e) {
      console.log(e);
      message.error(
        e?.response?.data?.status?.message || t("something_went_wrong"),
        5
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (session_id) {
      navigate(`transaction/detail/${session_id}`);
    }
  }, [session_id]);
  return (
    <div style={{ padding: "16px" }}>
      {isActive && transactionId ? (
        <ActiveCharging
          setIsActive={setIsActive}
          transactionId={transactionId}
        />
      ) : (
        <ScanQR onChange={onChangeQR} />
      )}
      <RecentCharges setIsActive={setIsActive} setTransactionId={setTransactionId}/>
      <StatisticsChart />
    </div>
  );
};
