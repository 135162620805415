import moment from "moment";

export const formatDateToString = (date, patten = "DD-MM-YYYY HH:mm") =>
  date
    ? moment(date * 1000)
        .format(patten)
        .toString()
    : "";

export const formatShortTime = (date, patten = "HH:mm") =>
  date
    ? moment(date * 1000)
        .format(patten)
        .toString()
    : "";

export const formatShortDateToString = (date, patten = "DD-MM HH:mm") =>
  date
    ? moment(date * 1000)
        .format(patten)
        .toString()
    : "";

export const isToday = (date) => {
  const today = new Date();
  if (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  ) {
    return true;
  }
  return false;
};

export const calculatorChargingTime = (trans) => {
  if (trans.start_timestamp == null || trans.stop_timestamp == null) {
    return "0:00:00";
  }
  const start = formatDateToString(trans.start_timestamp);
  const stop = formatDateToString(trans.stop_timestamp);
  const ms = moment(stop, "DD-MM-YYYY HH:mm:ss").diff(
    moment(start, "DD-MM-YYYY HH:mm:ss")
  );
  const duration = moment.duration(ms);
  return Math.floor(duration.asHours()) + moment.utc(ms).format(":mm:ss");
};
