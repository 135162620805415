import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Card, Button, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ItemCharging } from "../../../components/items/charging";
import {
  apiGetTransactionsActive,
  apiGetTransactionsHistory,
} from "../../../services/CommonServices";

const { Title } = Typography;

const RecentCharges = ({ setIsActive, setTransactionId }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetch = async () => {
    try {
      setLoading(true);
      const [res, resActive] = await Promise.all([
        apiGetTransactionsHistory({ page: 0, size: 5 }),
        apiGetTransactionsActive({ page: 0, size: 5 }),
      ]);
      setData([...res?.data, ...resActive?.data]);
      if (resActive?.data?.length) {
        setIsActive(true);
        setTransactionId(resActive?.data?.[0]?.transaction_id);
      } else {
        setIsActive(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
    const intervalId = setInterval(() => {
      fetch();
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: "24px",
      }}
    >
      <Row justify="space-between" align="middle">
        <Col>
          <Title style={{ margin: 0, fontSize: "16px", fontWeight: "500" }}>
            {t("recent_charging")}
          </Title>
        </Col>
        <Col>
          {data?.length ? (
            <Button
              type="link"
              onClick={() => navigate("/transactions")}
              style={{
                color: "#B20070",
                padding: "0px",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              {t("all")}
            </Button>
          ) : (
            ""
          )}
        </Col>
      </Row>
      {data?.length ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            overflowY: "auto",
            flexDirection: "column",
          }}
        >
          {data.map((session, index) => (
            <ItemCharging session={session} index={index} key={index} />
          ))}
        </div>
      ) : (
        <Card style={{ marginTop: "8px", marginBottom: "8px" }}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("empty_transaction")}
          />
        </Card>
      )}
    </div>
  );
};

export default RecentCharges;
