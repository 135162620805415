import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Card, Button, Empty } from "antd";
import { ArrowDownOutlined } from "@ant-design/icons";
import "antd/dist/reset.css"; // Import AntD styles
import { Svgs } from "../../../components/Svgs";
import { useTranslation } from "react-i18next";
import { ItemCharging } from "../../../components/items/charging";
import {
  apiGetTransactionsActive,
  apiGetTransactionsHistory,
} from "../../../services/CommonServices";

const { Text } = Typography;

const List = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [size, setSize] = useState(10); // Initial size of 10

  const fetch = async (newSize) => {
    try {
      setLoading(true);
      const [res, resActive] = await Promise.all([
        apiGetTransactionsHistory({ page: 0, size: newSize }),
        apiGetTransactionsActive({ page: 0, size: 5 }),
      ]);
      setData([...res?.data, ...resActive?.data]);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch(size);
    const intervalId = setInterval(() => {
      fetch(size);
    }, 10000);
    return () => clearInterval(intervalId);
  }, [size]);

  return (
    <div
      style={{
        width: "100%",
        // height: "600px",
        display: "flex",
        flexDirection: "column",
        padding: "16px",
      }}
    >
      {data?.length ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            overflowY: "auto",
            flexDirection: "column",
          }}
        >
          {data.map((session, index) => (
            <ItemCharging session={session} index={index} key={index} />
          ))}
          {size < data.length && (
            <Button
              type="link"
              icon={<ArrowDownOutlined />}
              onClick={() => setSize(size + 5)}
              style={{ alignSelf: "center", marginTop: 12, color: "#B20070" }}
              loading={loading}
            >
              {t("view_more")}
            </Button>
          )}
        </div>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("empty_transaction")}
        />
      )}
    </div>
  );
};

export default List;
