import { message } from "antd";
import axios from "axios";
import i18n from "i18next";

const unauthorizedCode = [401];

const HttpService = axios.create({
  timeout: 60000,
  baseURL: process.env.REACT_APP_URL_API,
});

HttpService.interceptors.request.use(
  async (config) => {
    config.startTime = Date.now();
    const dataLocalStorage = JSON.parse(localStorage.getItem("auth"));
    const token = dataLocalStorage?.access_token;
    if (!config?.public && token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    const currentLanguage = i18n.language;
    const customer_id = dataLocalStorage?.customer_id;
    const mall_id = dataLocalStorage?.mall_id;
    const fingerprint = dataLocalStorage?.fingerprint;
    config.headers["Device-Id"] = fingerprint;
    config.headers["Language"] = currentLanguage;
    config.headers["User-Id"] = customer_id;
    config.headers["Client-Id"] = mall_id;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

HttpService.interceptors.response.use(
  ({ data }) => data,
  (error) => {
    const { response } = error;
    if (response && unauthorizedCode.includes(response.status)) {
      message.error("Phiên đăng nhập hết hạn, vui lòng đăng nhập lại!");
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default HttpService;
