import { ReactComponent as ChargerIconSvg } from "@assets/images/home/charger_icon.svg";
import { ReactComponent as ActiveChargingBgSvg } from "@assets/images/home/active_charging_bg.svg";
import { ReactComponent as BatteryIconSvg } from "@assets/images/home/battery_icon.svg";
import { ReactComponent as TimeChargingIconSvg } from "@assets/images/home/time_icon.svg";
import { ReactComponent as PlugIconSvg } from "@assets/images/home/plug_icon.svg";
import { ReactComponent as EVCIconSvg } from "@assets/images/home/evc_icon.svg";
import { ReactComponent as FlagViSvg } from "@assets/images/home/flag_vi.svg";
import { ReactComponent as FlagUsaSvg } from "@assets/images/home/flag_usa.svg";
import { ReactComponent as LogoAeonSvg } from "@assets/logo/logo_aeon.svg";
import { ReactComponent as LogoEVCSvg } from "@assets/logo/logo_evc.svg";
import { ReactComponent as IconLocationSvg } from "@assets/images/transaction/ic_location.svg";
import { ReactComponent as IconCharger1Svg } from "@assets/images/transaction/ic_charge_1.svg";
import { ReactComponent as IconTimerSvg } from "@assets/images/transaction/ic_timer.svg";
import { ReactComponent as IconTimeChargingSvg } from "@assets/images/transaction/ic_time_charging.svg";
import { ReactComponent as IconPlugSvg } from "@assets/images/transaction/ic_plug.svg";
import { ReactComponent as IconTagSvg } from "@assets/images/transaction/ic_tag.svg";
import { ReactComponent as IconEVCSvg } from "@assets/images/transaction/ic_evc.svg";
import { ReactComponent as IconFlashLightSvg } from "@assets/images/home/flash_light_icon.svg";
import { ReactComponent as IconTorchOffSvg } from "@assets/images/home/torch_off.svg";
import { ReactComponent as IconTorchOnSvg } from "@assets/images/home/torch_on.svg";

export const Svgs = {
  ChargerIconSvg,
  ActiveChargingBgSvg,
  BatteryIconSvg,
  TimeChargingIconSvg,
  PlugIconSvg,
  EVCIconSvg,
  FlagViSvg,
  FlagUsaSvg,
  LogoAeonSvg,
  LogoEVCSvg,
  IconLocationSvg,
  IconCharger1Svg,
  IconTimerSvg,
  IconTimeChargingSvg,
  IconPlugSvg,
  IconTagSvg,
  IconEVCSvg,
  IconFlashLightSvg,
  IconTorchOffSvg,
  IconTorchOnSvg,
};
