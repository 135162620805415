import React from "react";
import { Card, Col, Row, Typography } from "antd";
import { Svgs } from "../Svgs";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  calculatorChargingTime,
  formatDateToString,
  formatShortDateToString,
  formatShortTime,
  isToday,
} from "../../utils";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

export const ItemCharging = ({ session, index }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card
      key={index}
      style={{
        width: "100%",
        marginBottom: 16,
        borderRadius: 12,
        padding: 16,
        height: "72px",
        cursor: "pointer",
      }}
      bodyStyle={{ padding: 0 }}
      headStyle={{ display: "flex", alignItems: "center", gap: 10 }}
      onClick={() => navigate(`/transaction/detail/${session?.transaction_id}`)}
    >
      <Row align="middle" gutter={16}>
        <Col>
          <div
            style={{
              width: 40,
              height: 40,
              backgroundColor: "rgba(178, 0, 112, 0.10)",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Svgs.ChargerIconSvg />
          </div>
        </Col>
        <Col flex="1 1 0">
          <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Row justify="space-between" align="middle">
              <Text
                style={{
                  color: "#18181B",
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {session.id_tag}
              </Text>
              <Text
                style={{
                  color: "#18181B",
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {session.power || 0} kWh
              </Text>
            </Row>
            <Row justify="space-between" align="middle">
              <Text style={{ color: "#52525B", fontSize: 12 }}>
                {t("duration")} {calculatorChargingTime(session)}
              </Text>
              <Text style={{ color: "#52525B", fontSize: 12 }}>
                {isToday(
                  new Date(moment(session.start_timestamp * 1000).toISOString())
                )
                  ? formatShortTime(session.start_timestamp)
                  : formatShortDateToString(session.start_timestamp)}
              </Text>
            </Row>
          </div>
        </Col>
      </Row>
    </Card>
  );
};
