import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Tooltip } from "antd";
import { BulbOutlined, BulbFilled } from "@ant-design/icons";
import "../index.css";
import QrScanner from "react-qr-scanner";
import { useTranslation } from "react-i18next";
import { Svgs } from "../../../components/Svgs";

export const ScanQR = ({ onChange }) => {
  const [scanResult, setScanResult] = useState("");
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const [isFlashlightOn, setIsFlashlightOn] = useState(false);
  const { t } = useTranslation();
  const videoTrackRef = useRef(null);

  const handleScan = (data) => {
    if (data) {
      setScanResult(data);
      onChange && onChange(data);
      setIsCameraVisible(false);
      toggleFlashlight(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const toggleCamera = () => {
    setIsCameraVisible(!isCameraVisible);
    if (!isCameraVisible) {
      setIsFlashlightOn(false);
    }
  };

  const toggleFlashlight = async (status) => {
    if (videoTrackRef.current) {
      const track = videoTrackRef.current;
      const capabilities = track.getCapabilities();
      if (capabilities.torch) {
        try {
          await track.applyConstraints({
            advanced: [{ torch: status }],
          });
          setIsFlashlightOn(status);
        } catch (error) {
          console.error("Error toggling flashlight:", error);
        }
      }
    }
  };

  useEffect(() => {
    if (isCameraVisible) {
      navigator.mediaDevices
        .getUserMedia({
          video: { facingMode: "environment" },
        })
        .then((stream) => {
          const track = stream.getVideoTracks()[0];
          videoTrackRef.current = track; // Store the video track reference
        });

      const videoElement = document.querySelector("video");
      if (videoElement) {
        videoElement.style.height = "197px";
        videoElement.style.borderRadius = "8px";
        videoElement.style.objectFit = "cover";
      }
    }

    return () => {
      // Clean up video track and flashlight state when the camera is hidden
      if (videoTrackRef.current) {
        toggleFlashlight(false);
        videoTrackRef.current.stop(); // Stop the video track when closing the camera
        videoTrackRef.current = null;
      }
    };
  }, [isCameraVisible]);

  const scannerStyle = {
    width: "100%",
    height: "197px",
    borderRadius: "8px",
    overflow: "hidden",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px",
  };

  const frameStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "70%",
    pointerEvents: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const cornerStyle = {
    position: "absolute",
    width: "40px",
    height: "40px",
  };

  const cornerPositions = {
    topLeft: {
      top: "-2px",
      left: "-2px",
      borderTop: "2px solid #FFF",
      borderLeft: "2px solid #FFF",
    },
    topRight: {
      top: "-2px",
      right: "-2px",
      borderTop: "2px solid #FFF",
      borderRight: "2px solid #FFF",
    },
    bottomLeft: {
      bottom: "-2px",
      left: "-2px",
      borderBottom: "2px solid #FFF",
      borderLeft: "2px solid #FFF",
    },
    bottomRight: {
      bottom: "-2px",
      right: "-2px",
      borderBottom: "2px solid #FFF",
      borderRight: "2px solid #FFF",
    },
  };

  return (
    <>
      <Card className="custom-card" bodyStyle={{ padding: "0px" }}>
        <div style={{ fontWeight: "500", fontSize: "18px" }}>
          {t("start_charging")}
        </div>
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            marginBottom: "24px",
            color: "#52525B",
          }}
        >
          {t("guide_scan")}
        </div>
        {!isCameraVisible && (
          <Button
            type="primary"
            className="start-button"
            onClick={toggleCamera}
          >
            {t("scan_QR")}
          </Button>
        )}
        {isCameraVisible && (
          <div style={scannerStyle}>
            <QrScanner
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%", height: "100%" }}
              constraints={{
                video: { facingMode: "environment" },
              }}
            />
            <div style={frameStyle}>
              <div style={{ ...cornerStyle, ...cornerPositions.topLeft }}></div>
              <div
                style={{ ...cornerStyle, ...cornerPositions.topRight }}
              ></div>
              <div
                style={{ ...cornerStyle, ...cornerPositions.bottomLeft }}
              ></div>
              <div
                style={{ ...cornerStyle, ...cornerPositions.bottomRight }}
              ></div>
            </div>

            <Tooltip
              title={
                isFlashlightOn
                  ? t("turn_off_flashlight")
                  : t("turn_on_flashlight")
              }
            >
              <Button
                type="primary"
                shape="circle"
                icon={
                  isFlashlightOn ? (
                    <Svgs.IconTorchOnSvg />
                  ) : (
                    <Svgs.IconTorchOffSvg />
                  )
                }
                onClick={() => toggleFlashlight(!isFlashlightOn)}
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  zIndex: 1,
                  width: "40px",
                  height: "40px",
                  backgroundColor: isFlashlightOn ? "#FFFFFF" : "#FFFFFF33",
                }}
              />
            </Tooltip>
          </div>
        )}
        {isCameraVisible && (
          <Button
            type="primary"
            style={{
              backgroundColor: "#FFF",
              borderWidth: "1px",
              borderColor: "#E4E4E7",
              width: "100%",
              marginTop: "24px",
              color: "#18181B",
              fontWeight: "600",
              height: "44px",
            }}
            onClick={toggleCamera}
          >
            {t("cancel")}
          </Button>
        )}
        <div
          style={{
            fontWeight: "400",
            fontSize: "14px",
            marginTop: "16px",
            color: "#52525B",
          }}
        >
          Power by{" "}
          <span
            style={{ color: "#2D9764", fontWeight: "500", cursor: "pointer" }}
            onClick={() => {
              window.open("https://evercharge.vn/");
            }}
          >
            Evercharge
          </span>
        </div>
      </Card>
    </>
  );
};
